import React from 'react';
import './EverythingElse2.css';
import EverythingElse2__LitePaperModule from './EverythingElse2__LitePaperModule.js'
import baseballCap from './img/baseball-cap.png';
// import increase from './img/increase.png';
import increase from './img/bar-chart.png';
import beaker from './img/beaker.png';
import wallet from './img/wallet.png';
import marketing from './img/promotion.png';
// import partnership from './img/partnership.png';
import partnership from './img/deal (1).png';
import transparency from './img/study.png';
import alex from './img/alex.jpg';

const EverythingElse2 = (props) =>{

    return (
        <div className='everything-else2'>
          <p className='everything-else2__content'>
	          {/*<p className='title'>Presale</p>*/}
	          <p className='text'>
	              		WhaleSafe is available via a presale from 11am PDT to 1pm PDT on July 19.
	              		Immediately thereafter, it will be available for purchase on PancakeSwap.
	              		If you want to participate in the presale, follow the steps below.
	              		If you have questions, feel free to contact us at whalesafe@whalesafecrypto.com.
	              		<br></br><br></br>
	              		Presale link: <a href='https://dxsale.app/app/v2_9/defipresale?saleID=1212&chain=BSC'>https://dxsale.app/app/v2_9/defipresale?saleID=1212&chain=BSC</a>
	              </p>
	          <EverythingElse2__LitePaperModule
	              title='1. Get a MetaMask wallet and Connect it to the Binance Smart Chain Mainnet'
	              text="
						<br/>
						- Install the <a href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'>Chrome extension<a/> and follow the instructions <a href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain'>here<a/> to connect the wallet to the Binance Smart Chain Mainnet.
              			"
	              />
	          <EverythingElse2__LitePaperModule
	              title='2. Get BNB'
	              text="
			              <br/>
			              - Purchase BNB from a centralized exchange like Binance or Crypto.com if you already have a verified account (verification takes a few days). If you don't, Moonpay, Simplex, or Paybis can get you BNB immediately.
			              "
	              />
	          <EverythingElse2__LitePaperModule
	              title='3. Contribute to the presale'
	              text="
	              		  <br/>
						  - Go to the <a href='https://dxsale.app/app/v2_9/defipresale?saleID=1212&chain=BSC'>presale</a>
						  <br/>
						  - Press 'Connect Wallet' and follow the prompts
						  </br>
						  - Follow the instructions in this 1:36 video below
						  <div class='videoWrapper'>
							  <iframe width='560' height='315' src='https://www.youtube.com/embed/iAZVoOONAIg' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>
						  </div>
						  "
	              />
	      </p>
        </div>
    );

}

export default EverythingElse2;