import React from 'react';
import { Navbar, NavItem, NavDropdown, MenuItem, Nav, Form, FormControl, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css'
// import logo from './img/whaleSafev2-01.png';
// import logo from './img/whaleSafev4_6.png';
import logo from './img/whaleRedSmallWHT.png';

const Menu = (props) =>{

    // const handleDropdownClick = (event) => {
    //   // const timer = setTimeout(() => {
    //   //   const el1 = document.querySelector('.mr-auto')
    //   //   console.log(el1)
    //   // },500)
    //   // return () => {
    //   //   clearTimeout(timer)
    //   // }
    //   console.log('hi')
    // }

    const dropdownOpen = (event) => {
      const mrAuto = document.querySelector('.mr-auto');
      mrAuto.classList.remove('fade-out');
      mrAuto.classList.add('fade-in');
      handleDropdownClick(event)
    }

    const dropdownClose = (event) => {
      const mrAuto = document.querySelector('.mr-auto');
      mrAuto.classList.remove('fade-in');
      mrAuto.classList.add('fade-out');
      handleDropdownClick(event)
    }

    const handleDropdownClick = () => {
      console.log('Before');
      const mrAuto = document.querySelector('.mr-auto');
      const dropdownLinks = document.querySelectorAll('.navbar-custom__link');
      // console.log(dropdownLinks)
      mrAuto.classList.toggle('open');
      dropdownLinks.forEach(element => element.classList.toggle('visible'));
      console.log('After');
    }

    const transformClose = () => {
      // const toggleBtnContainer = document.querySelector('.toggle-btn-container');
      // toggleBtnContainer.classList.toggle("change");
      handleDropdownClick()
    }

// useEffect(() => {
//   const timer = setTimeout(() => {
//     const el1 = document.querySelector('.el1')
//     const el2 = document.querySelector('.el2')
//   },500)
//   return () => {
//     clearTimeout(timer)
//   }
// }, [])


    // return (
    //         <Navbar className='navbar-custom' bg='light' expand="lg" onToggle={ dropdownOpen }>
    //           <Navbar.Brand className='navbar-custom__home' href="#home"><img src={logo} className='logo' /></Navbar.Brand>
    //           <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //           <Navbar.Collapse id="basic-navbar-nav">
    //             <Nav className="mr-auto">
    //               {/*<Nav.Link className='navbar-custom__link' onClick={ handleOnClick0 }>Home</Nav.Link>*/}
    //               <Nav.Link className='navbar-custom__link close-btn invisible' onClick={ dropdownClose }>Close</Nav.Link>
    //               <Nav.Link className='navbar-custom__link' onClick={ handleOnClick1 }>Buy</Nav.Link>
    //               <Nav.Link className='navbar-custom__link' onClick={ handleOnClick2 }>Chart</Nav.Link>
    //               <Nav.Link className='navbar-custom__link' href="#link">Whitepaper</Nav.Link>
    //             </Nav>
    //           </Navbar.Collapse>
    //         </Navbar>
    // );

    return (
            <Navbar className='navbar-custom' bg='light' expand="lg">
              <Navbar.Brand className='navbar-custom__home' href="https://whalesafecrypto.com"><img src={logo} className='logo' /></Navbar.Brand>
              {/*<button className='toggle-btn' onClick={ dropdownOpen }>ToggleBtn</button>*/}
              <div className='toggle-btn'>
                <div className="toggle-btn-container" onClick={ handleDropdownClick }>
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
              </div>
                <Nav className="mr-auto">
                  {/*<Nav.Link className='navbar-custom__link close-btn' onClick={ dropdownClose }>Close</Nav.Link>*/}
                  <div className="navbar-custom__link close-btn" onClick={ handleDropdownClick }>
                    <div className="close-btn-container">
                      <div className="close-bar1"></div>
                      <div className="close-bar2"></div>
                    </div>
                  </div>
                  <a className='navbar-custom__link' href="https://presale.whalesafecrypto.com">Presale</a>
                  {/*<a className='navbar-custom__link' href="https://poocoin.app">Chart</a>*/}
                  {<a className='navbar-custom__link' href="https://whitepaper.whalesafecrypto.com">Whitepaper</a>}
                  <a className='navbar-custom__link' href="https://team.whalesafecrypto.com">Team</a>
                </Nav>
            </Navbar>
    );
}

export default Menu;