import React from 'react';
import './Waves3.css';
import { useRef, useEffect } from 'react';
// import logo from './img/whaleSafev2-01.png';
// import logo from './img/whaleSafev4_6.png';
import logo from './img/whaleRedSmallWHT.png';
// import {ReactComponent as WaveImage} from './img/wave.svg';
// import waveImage from './img/waves_image6-removebg-preview.png';
// import waveImage from './img/oie_trans (1).gif';
import waveImage from './img/waves_drawing.png';
// import moonImage from './img/moon_image1-removebg-preview.png';
import moonImage from './img/moon.png';
import EverythingElse2 from './EverythingElse2.js';

const Waves = (props) =>{

    return (
        <div className='waves'>
			<div class='stars'></div>
			<div class='stars2'></div>
			<div class='stars3'></div>
            <EverythingElse2 />
            <img className='moon-img' src={ moonImage }></img>
        </div>
    );

}

export default Waves;