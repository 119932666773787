import './App.css';
import Navbar from './components/Navbar';
// import Waves from './components/Waves';
import Waves3 from './components/Waves3';
import EverythingElse1 from './components/EverythingElse1';
import EverythingElse2 from './components/EverythingElse2';
// import TeamProfile from './components/TeamProfile';
import { useRef, useEffect } from 'react';
// import { ReactComponent as Logo } from './components/img/logo.svg';

function App() {

  document.title = 'WhaleSafe'

  return (
    <div className="App">
      <link rel="preconnect" href="https://fonts.gstatic.com"/>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto&display=swap" rel="stylesheet"/>
      <Navbar />
      <Waves3 />
    </div>
  );
}

export default App;
